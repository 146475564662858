/***** CSS added 20240616 *****/
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700;800&display=swap');

:root {
  --Orange: #F57F20;
  --Seadog-Blue: #3D67A8;  
  --Painting-Pink: #E472A4;
  --Heckin-Red: #E65139;
  --Retriever-Gold: #FFC70A;
  --Black: #000000;
  --White: #ffffff;
  --Bone: #F6DEBA;
  --Tasty-Bone: #FAEEDD;
  --Platinum-Green: #E6EDED;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Montserrat', sans-serif !important;
  margin: 0;
}

.page-checkout main, .page-try main, .page-chdi main, .page-paramount main, .page-paw30 main, 
.page-pups30 main, .page-promo256m main, .page-love main {
  background-color: var(--Bone);
}

.atcheckout .nav-item:not(:first-child), .startnow, .onpetsmart, 
.ondogday, .ontry, .onchdi, .onparamount, .onpups30, .onpaw30, .onpromo256m, .ontestimonials, .onlove {
  display: none;
}

.page-home .startnow,
.page-privacy .startnow,
.page-terms .startnow,
.page-petsmart .onpetsmart,
.page-dogday .ondogday,
.page-try .ontry,
.page-pups30 .onpups30,
.page-paw30 .onpaw30,
.page-chdi .onchdi,
.page-paramount .onparamount,
.page-promo256m .onpromo256m,
.page-testimonials .ontestimonials,
.page-love .onlove
 {
  display: block;
}


.page-success main {
  background-color: var(--Black);
}

.hide {
  display: none !important;
}

header, footer {
  background-color: var(--Black);
  color: var(--White);
  padding: 0px 20px;
}

@media (max-width:578px) {
  header {
    padding: 0 0 0 10px;
  }
}

header nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height:80px;
  .logo-img {
    height:44px;
    @media (max-width:820px) {
      height:40px;
    }
  }
}

header .navbar-nav {
  display: flex;
  gap: 20px;
  list-style: none;
  @media (max-width:578px) {
    flex-direction: row;
    gap: 10px;
  }
}

header .navbar-nav a, footer a {
  color: var(--White); 
  text-decoration: none;
}
header .navbar-nav a:hover {
  text-decoration: underline;
}

.add-white-bg {
  background-color: var(--White);
  border-radius: 12px;
  padding: 10px;
}

.pr-15 {
  padding-right: 15px;
  @media (max-width:820px) {
    padding-right: 0px;
  }
}



@media (max-width:576px) {
  .pt-mob {
    padding-top:24px;
  }
  header .navbar-collapse {
    background-color: var(--Black);
    padding: 20px;
  }
}

.navbar-toggler, .sign-in-icon {
  display: none; 
}
@media (max-width: 576px) {
  header nav {
    height: 49px;
  }
  .navbar-brand {
    margin-right: 0 !important;
    img {
      width: 120px;
      object-fit: contain;
    }
  }
  .sign-in-text {
    display: none; 
  }

  .sign-in-icon {
    display: inline !important; 
  }

  .navbar-dark {
    padding: 0 !important;
  }

  .navbar-nav {
    display: flex; 
  }
}

footer {
  .logo-img {
    height:44px;
    @media (max-width:820px) {
      height:32px;
    }
  }
}

footer .socials {
  padding-right:100px;
  @media (max-width:1000px) {
    padding-right: 0;
  }
}

.w-md-75 {
  width: 75% !important;
  @media (max-width:800px) {
    width: 100% !important;
  }
}

#register .error {
  color: var( --Heckin-Red);
}

.withbgimage {
  flex: 1;
  background-repeat: no-repeat;
  background-size: cover;
  color: var( --Black);
  text-align: center;
  padding: 40px;
  @media (max-width:1200px) {
    padding:20px 10px;
  }
  .row {
    max-width: 1280px;
    margin:0 auto;
    @media (max-width:1200px) {
      max-width: 95%;
    }
  }
}

.whats-inside-section {
  background-image: url('../src/assets/paws_v3.svg') !important;
}

@media (max-width: 768px) {
  .whats-inside-section {
    background-image: url('../src/assets/pawsOnMobile.svg') !important;
  }
}

h1, .plan-section h1.section-title {
  margin:0 auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 72px !important;
  font-weight: 800 !important;
  line-height: normal; 
  @media (max-width:1024px) {
    font-size: 32px !important;
  }
}

h2 {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 48px !important;
  font-weight: 800 !important;
  line-height: 1.2; 
  @media (max-width:1024px) {
    font-size: 32px !important;
  }
}

h3 {
  font-family: 'Montserrat';
  font-size: 40px !important;
  font-weight: 700 !important;
  line-height: normal;
  letter-spacing: -0.88px; 
  @media (max-width:1024px) {
    font-size:32px !important;
  }
}

h4 {
  font-family: 'Montserrat';
  font-size: 32px;
  font-weight: 700 !important;
  line-height: 140%; 
  letter-spacing: 0.704px;
}

h5, .chosen-plan {
  font-family: 'Montserrat';
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 140%; 
  letter-spacing: 1.26px;
  text-transform: uppercase; 
  @media (max-width:800px) {
    font-size: 14px !important;
  }
}

p:last-child {
  margin-bottom: 0;
}

.plan-section p.section-subtitle {
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 500; 
  padding:10px 0 0;
  @media (max-width:1200px) {
    font-size: 20px;
  }
}

.fs-small {
  font-size: 16px;
  @media (max-width:800px) {
    font-size: 12px !important;
  }
}

strong, .strong {
  font-weight: 800;
}

.underline {
  text-decoration: underline;
}

hr.spacer {
  color: transparent;
  content:'';
  display:block;
  height:13px;
}

hr.h-40 {
  color: transparent;
  margin:20px auto;
  @media (max-width:1200px) {
    margin:10px auto;
  }
}

.plan-section .select-offer {
  background-color:var(--White);
  border:0px;
  border-radius: 12px;
  margin-bottom: 10px;
  padding: 30px;
  max-width: 500px;
  text-align: left;;
}

.plan-section .offer-1 {
  border: 4px solid var(--Orange);
  @media (min-width:1024px) {
    padding: 50px 30px;
  }
}

.page-home .plan-section .offer-1,
.page-testimonials .plan-section .offer-1 {
  border: 4px solid var(--Orange);
  @media (min-width:1024px) {
    padding: 30px;
  }
}

.page-home .plan-section .offer-2,
.page-testimonials .plan-section .offer-2 {
  border: 4px solid var(--Heckin-Red);
  @media (min-width:1024px) {
    padding: 50px 30px;
  }
}

.cardtag {
  background-color:var(--Heckin-Red);
  border-radius: 20px;
  color:var(--White);
  font-weight: bold;
  left: 50%;
  padding: 5px;
  position: absolute;
  text-align: center;
  top: -20px;
  transform: translateX(-50%);
  width: 280px;
}

.btn-red {
  background-color: var( --Heckin-Red) !important;
  color:var(--White) !important;
}

.btn-red:hover {
  background-color: var( --Retriever-Gold) !important;
  color:var(--White) !important;
}

/*** Howliday made disabled this css
.plan-section .offer-1 {
  border: 4px solid var(--Orange);
  @media (min-width:1024px) {
    padding: 50px 30px;
  }
}

 hiding third offer 
.page-home .plan-section .col-md-4:nth-child(3),***/
.page-try .plan-section .col-md-4:nth-child(3),
.page-chdi .plan-section .col-md-4:nth-child(3) {
  display: none !important;
}


.select-offer .card-body {
  padding: 0;
}
.select-offer h3 {
  color: var( --Seadog-Blue);
}

.select-offer .small-text {
  font-size:14px;
}

.select-offer .price-text {
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 700; 
  @media (max-width:1200px) {
    font-size:24px;
  }
}

.select-offer button, form input[type=submit] {
  background-color: var( --Orange);
  border: none;
  border-radius: 12px;
  color: var(--White);
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 700;
  height: 56px;
  padding: 4px;
  width: 100%;
  transition: background-color 0.3s ease; 
}

.select-offer button:hover, input[type=submit]:hover {
  background-color: var( --Retriever-Gold);
}

.regform {
  background-color: var(--White);
  border: 0;
  border-radius: 12px;
  margin: 0 auto !important;
  max-width: 700px !important;
  text-align: left;
  padding: 30px;
  @media (max-width: 575.98px){
    padding: 15px;
  }
}

input:not([type="file" i], [type="image" i], [type="checkbox" i], [type="radio" i]) {
  background-color: rgba(246, 222, 186, 0.5); 
  border-radius: 4px; 
  border:none;
  padding: 16px;
}

#dropin-container {
  margin: 30px auto;
}

label.chosen-plan {
  color: var(--Seadog-Blue);
} 

#register {
  width: 100%;
  max-width: 600px;
  margin: auto;
}

#register .password-fields, .name-fields {
  display: flex;
  gap: 20px; 
  @media (max-width:578px) {
    display: block;
  }
}

.password-fields .field, .name-fields .field {
  flex: 1;
}

#register .password-fields input,
#register .name-fields input {
  width: 100%;
}

#register label {
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  margin-top:14px;
  a {
    text-decoration: underline;
    color: var(--Black);
  }
}

#register input[type="text"],
#register input[type="email"] {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 4px;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 0px;
}

#register .consent-field {
  display: flex;
  align-items: center;
  margin-top: 24px;
  gap: 10px;
}

/* Change the checkbox color when checked */
input[type="checkbox"]:checked {
  background-color: transparent !important; 
  appearance: none; 
  font-size:10px !important;
}

/* Optional: Change the color of the checkmark */
input[type="checkbox"]:checked::before {
  content: '\2713'; 
  color: white !important; 
  display: block;
  position: relative;
  padding: 0 4px;
  background-color: var(--Orange) !important;
  border-radius: 4px;
  top: -2px; 
  left: 0px;
  font-size: 10px;
}

#register .consent-field label {
  margin-left: 10px;
  margin-top: 0 !important;
}

/* Change the border color of text inputs when focused */
#register input[type="text"]:focus,
#register input[type="email"]:focus,
#register input[type="password"]:focus {
  outline: 2px solid var(--Orange);  
}

/* Optional: Add transition for smooth color change */
#register input[type="text"],
#register input[type="email"],
#register input[type="password"] {
    transition: border-color 0.3s ease;
}

#dropin-container {
  align-items: start;
  text-align: left;
}

.adyen-checkout__card__brands {
  height: 24px !important;
}

.adyen-checkout__button.adyen-checkout__button--pay {
  background-color: var(--Orange) !important;
  width: 100%;
  
}
.adyen-checkout__button:hover {
  background: var(--Retriever-Gold) !important;
  box-shadow: none !important;
}

.adyen-checkout__payment-method__header__title {
    padding: 4px 48px 4px 0 !important;
    justify-content: start !important;
    text-align: left !important;
}

.adyen-checkout__payment-method__header {
  padding:0px 16px 0px 44px !important;
}

.adyen-checkout__payment-method--selected {
  padding:20px !important;
}

/***** Bootstrap Responsive Specific *****/
@media (min-width: 768px) {
  .col-md-1 {
      flex: 0 0 auto;
      width: 12% !important;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 14% !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .pl1rem {
    padding-left: 1.1rem;
  }
}


@media (max-width: 575.98px) {
  .mx-auto-mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .px-mobile {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}

.spinner-border.text-orange {
  border-color: var(--Orange);
  border-right-color: transparent;
  border-bottom-color: transparent;
}

.deskonly {
  display: none;
  @media (min-width: 769px) {
      display: block;
    }
}
.mobonly {
  display: block;
  @media (min-width: 768px) {
      display: none;
    }
}

@media (min-width: 769px) {
  .desktop-image {
    display: block;
  }
  .mobile-image {
    display: none;
  }
}

.py-5-mobile-override {
  padding-top: 48px;
  padding-bottom: 48px;
  @media (max-width:768px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }
}

.container-fluid {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .row {
  max-width: 1320px; 
  margin:0 auto;
  }
  .stretched {
    max-width: 100% !important;
  }
}
.btn, .regform button {
  border:none !important;
  border-radius: 12px !important;
  font-family: "Montserrat";
  font-size: 18px !important;
  font-weight: 700 !important;
  width: 282px;
  height: 56px;
  padding: 4px 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.btn-24 {
  font-family: "Montserrat";
  font-size: 18px !important;
  font-weight: 700 !important;
  height: 48px; 
  letter-spacing: 0.09px;
  color:var(--White) !important; 
  padding:10px 32px !important;
  border-radius: 12px !important;
  border: 2px solid var(--Platinum-Green) !important;
  @media (max-width:800px) {
    text-align: center;
  }
}

.btn-12 {
  font-family: "Montserrat";
  font-size: 18px !important;
  font-weight: 700 !important;
  height: 48px; 
  letter-spacing: 0.09px;
  color:var(--White) !important; 
  padding:10px 32px !important;
  border-radius: 12px !important;
  background: var(--Orange) !important;
  @media (max-width:800px) {
    text-align: center;
  }
}

@media (max-width:578px) {
  .btn-24 {
    display: none !important;
  }
  .page-success .btn-24 {
    display: flex !important;
    justify-content: center;
    align-items: center;
    align-content: center;
    border: 0 !important;
    border-radius: 0 !important;
    margin: auto !important;
    padding:10px 12px !important;
  }
  .btn-12 {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    border-radius: 0 !important;
    padding: 10px !important;
  }
}

.btn-24:hover, .btn-12:hover {
  text-decoration: none !important;
  background: var(--Retriever-Gold) !important;
}

.btn-primary, .btn-hero {
  background-color: var( --Orange) !important;
  color:var(--White) !important;
}
.btn-black:hover, .btn-primary:hover {
  background-color: var( --Retriever-Gold) !important;
  color:var(--White) !important;
}

.btn-hero:hover {
  background-color: var( --Black) !important;
  color:var(--White) !important;
}
.page-success .startnow {
  display: none !important;
}

.regform .btn-paypal {
  margin:20px auto;
}

.btn-yellow {
  background-color: var( --Retriever-Gold) !important;
  color: var(--White) !important;
}
.btn-yellow:hover {
  background-color: var( --Orange) !important;
}
.btn-black {
  background-color: var( --Black) !important;
  color: var(--White) !important;
}
.regform .btn-paypal {
  background-color: var( --White) !important;
  border: 2px solid var( --Black) !important;
  transition: all 0.5s ease-in-out;
}

.regform .btn-paypal:hover {
  background-color: var( --Black) !important;
  color: var(--White) !important;
}

@keyframes dropIn {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.checklist, .punchlist, .planlist {
  list-style: none;
  padding: 0;
}

.checklist li, .planlist li {
  position: relative;
  padding-left: 30px; 
  margin-bottom: 10px; 
  font-size: 18px; 
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 20px; 
  animation: dropIn 0.5s ease forwards;
}

.page-paramount .planlist li {
  position: relative;
  padding-left: 30px; 
  margin-bottom: 10px; 
  font-size: 14px; 
  font-weight: 500;
  background-position: left center;
  background-repeat: no-repeat;
  background-size: 20px 20px; 
  animation: dropIn 0.5s ease forwards;
}
.checklist li:nth-child(1) {
  animation-delay: 0.1s;
}

.checklist li:nth-child(2) {
  animation-delay: 0.2s;
}

.checklist li:nth-child(3) {
  animation-delay: 0.3s;
}

.checklist li:nth-child(4) {
  animation-delay: 0.4s;
}

.checklist li:nth-child(5) {
  animation-delay: 0.5s;
}

.checklist li:nth-child(6) {
  animation-delay: 0.6s;
}

.punchlist li {
  position: relative;
  margin-top: 24px;
  font-family: 'Montserrat' !important; 
  font-size: 18px;
  line-height: 1.4;
  background-position: top left;
  background-repeat: no-repeat;
  padding-left: 84px;
  background-size: 60px;
  animation: dropIn 0.5s ease forwards;
  @media (max-width:768px) {
    padding-left: 64px;
    background-size: 48px;
  }
}

.punchlist li:nth-child(1) {
  animation-delay: 0.1s;
}

.punchlist li:nth-child(2) {
  animation-delay: 0.2s;
}

.punchlist li:nth-child(3) {
  animation-delay: 0.3s;
}

.punchlist li:nth-child(4) {
  animation-delay: 0.4s;
}

.punchlist li:nth-child(5) {
  animation-delay: 0.5s;
}

.section-with-diagonal {
  position: relative;
  overflow: hidden;
}


/***** Custom shape divider animation *****/
.custom-shape-divider-bottom {
  position: relative;
  width: 100%;
  height: 80px; 
  overflow: hidden;
  line-height: 0;
  z-index: 5;
}

.custom-shape-divider-bottom svg {
  position: absolute;
  bottom: -40px;
  display: block;
  width: calc(100% + 1.3px);
  height: 80px;
}

.shape-fill {
  fill: var(--White);
}

.shape-fill2 {
  fill: var(--Retriever-Gold);
}

.shape-fill3 {
  fill: var(--Black);
}

.animate-shape .shape-fill,
.animate-shape .shape-fill2,
.animate-shape .shape-fill3 {
  animation: slideIn 1.5s ease-in-out forwards;
}

@keyframes slideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

.logos-section {justify-content: center;}

.custom-shape-divider-top {
  position: relative;
  width: 100%;
  height: 80px;
  overflow: hidden;
  line-height: 0;
  z-index: 5;
  transform: scaleX(-1); 
}

.custom-shape-divider-top svg {
  position: absolute;
  display: block;
  width: calc(100% + 1.3px);
  height: 80px;
  bottom: -40px;
}

/* Optional: Keep the existing animation if needed */
.shape-divider-animation.animate-shape .shape-fill {
  animation: slideIn 1.5s ease-in-out forwards;
}

/***** FAQs Accordion *****/
.accordion {
  border: 0px solid #ddd;
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: var(--Seadog-Blue);
}

.accordion-item {
  border: 0px !important;
  background-color: var(--Seadog-Blue) !important;
}

.accordion-header {
  padding: 30px 20px;
  margin: 8px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--Seadog-Blue);
  border-radius: 10px !important;
  border: 1px solid var(--White);
  transition: background-color 0.3s ease-in-out, border-radius 0.15s ease-in-out;
}

.accordion-header h6 {
  margin: 0;
  font-size: 16px;
  color: var(--White);
}

.accordion-header span {
  font-size: 24px;
  line-height: 1;
  color: var(--White);
}

.accordion-header.active {
  background-color: var(--White);
  border-radius: 10px 10px 0 0 !important;
}

.accordion-header.active h6 {
  color: var(--Black);
  font-weight: 700;
}

.accordion-content {
  padding: 0 20px;
  background-color: var(--White);
  border-radius: 0 0 10px 10px !important;
  color: var(--Black);
  font-size: 18px;
  font-weight: 300;
  line-height: 160%;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.accordion-header .chevron-icon svg {
  color: var(--White); 
}

.accordion-header.active .chevron-icon svg {
  color: var(--Seadog-Blue); 
}


.accordion-content.active {
  max-height: 500px; 
  padding: 0px 20px 30px; 
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.animated-image {
  animation: zoomIn 1s ease-in-out;
}

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

.animated-image.floating {
  animation: floating 3s ease-in-out infinite;
}

.zoom-img {
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.zoom-img:hover {
  transform: scale(1.03); 
}

/***** Photo Gallery *****/
.photo-gallery {
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px; 
  overflow: hidden;
  position: relative;
  width: 100%;
}

.photo-gallery-inner {
  display: flex;
  width: 200%; 
}

.photo-gallery img {
  width: 100%;
  height: auto;
  border-radius: 14px;
}
.photo-img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.photo-img:hover {
  transform: scale(1.03); 
}
/*
.photo-link img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.photo-link:hover img {
  transform: scale(1.1);  
}
*/

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.slide-left {
  animation: scroll-left 60s linear infinite;
  @media (max-width:768px){
    animation: scroll-left 30s linear infinite;
  }
}

.slide-right {
  animation: scroll-right 60s linear infinite reverse;
  @media (max-width:768px){
    animation: scroll-right 30s linear infinite reverse;
  }
}

.animated-photo {
  box-sizing: border-box;
  margin: 0 10px;
}

.animated-photo img {
  width: 480px;
  height: auto;
  @media (max-width:1024px) and (min-width:701px) {
    width: 320px;
  }
  @media (max-width:700px) {
    width: 150px;
  }
}

/***** Hero Section *****/
.hero-background {
  position: relative;
  overflow: hidden;
  background-color: var(--Black);
  background-position-x: right;
}

.hero-background .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85);
  z-index: 2; 
}

.hero-background .row {
  z-index: 9;
  position: relative;
}

/*
.hero-background {
  position: relative;
  overflow: hidden;
  background-color: var(--Black);
}

.hero-background .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 2; 
}
.hero-background .photo-gallery {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1; 
  padding: 0; 
  margin: 0; 
}

.hero-background .photo-gallery-inner {
  display: flex;
  height: 190px;
}

.hero-background .animated-photo {
  margin: 20px 10px;
  flex: 1 1 100%;
  img {
    width: 340px;
    height: auto;
    @media (max-width:1024px) and (min-width:701px) {
      width: 320px;
    }
    @media (max-width:700px) {
      width: 320px;
    }
  }
}

.hero-background .photo-img {
  width: 100%;
  height: 160px;
}

.hero-background .slide-left {
  animation: scroll-left 240s linear infinite; 

.hero-background .slide-right {
  animation: scroll-right 240s linear infinite reverse; 
}

.sticky-bottom-container {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: var(--Orange);
    z-index: 999;
  }
  
  .hidden {
    display: none !important;
  }
    
  .nostickybtn {
    z-index: 1000;
  }
*/

  @media (max-width: 767px) { 
    .nobgimagemobile {
      background-image: none !important;
    }
  }
   

/***** termItems Accordion *****/
#terms .accordion {
  border: 0px solid var(--Black);
  border-radius: 10px;
  margin-bottom: 20px;
  background-color: var(--White);
}

#terms .accordion-item {
  border: 1px solid var(--White);
  background-color: var(--White) !important;
}

#terms .accordion-header {
  padding: 30px 20px;
  margin: 8px 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--White);
  border-radius: 10px !important;
  border: 1px solid var(--Black);
  transition: background-color 0.3s ease-in-out, border-radius 0.15s ease-in-out;
}

#terms .accordion-header h6 {
  margin: 0;
  font-size: 16px;
  color: var(--Black);
}

#terms .accordion-header span {
  font-size: 24px;
  line-height: 1;
  color: var(--White);
}

#terms .accordion-header.active {
  background-color: var(--White);
  border: 1px solid var(--Black);
  border-bottom: 0;
  border-radius: 10px 10px 0 0 !important;
  margin-bottom: 0px;
}
#terms .accordion-header.active h6 {
  color: var(--Black);
}

#terms .accordion-header .chevron-icon svg {
  color: var(--Black); /* Default color */
}

#terms .accordion-header.active .chevron-icon svg {
  color: var(--Black); /* Active color */
}

#terms .accordion-content.active {
  background-color: var(--White);
  border: 1px solid var(--Black);
  border-top: 0;
}

#dropin-container .adyen-checkout__button__icon {
  display: none;
}

span.adyen-checkout__button__text {
  font-size: 0px !important;
}
span.adyen-checkout__button__text::after {
  content: 'START WATCHING';
  font-family: "Montserrat";
  font-size: 18px !important;
  font-weight: 700 !important;
  text-align: center;
}


/***** PetSmart Page *****/
:root {
  --PetSmart-Blue: #206EF6;
  --PetSmart-Red: #DD2733;
}

.page-petsmart h3 {
  font-size: 36px !important;
  font-weight: 900 !important;
  @media (max-width:578px) {
    font-size: 29px !important;
  }
}

.page-petsmart .btn-primary, .page-petsmart .btn-12,
.page-dogday .btn-primary, .page-dogday .btn-12 {
  background-color: var(--PetSmart-Blue) !important;
}
.page-petsmart .btn-primary:hover, .page-petsmart .btn-12:hover, .page-petsmart .btn-24:hover,
.page-dogday .btn-primary:hover, .page-dogday .btn-12:hover, .page-dogday .btn-24:hover {
  background-color: var(--PetSmart-Red) !important;
}

.page-petsmart .accordion, .page-dogday .accordion {
  background-color: var(--PetSmart-Blue);
}

.page-petsmart .accordion-item,
.page-petsmart input[type="checkbox"]:checked::before,
.page-dogday .accordion-item,
.page-dogday input[type="checkbox"]:checked::before {
  background-color: var(--PetSmart-Blue) !important;
}

.page-petsmart .accordion-header, 
.page-petsmart form input[type=submit],
.page-dogday .accordion-header, 
.page-dogday form input[type=submit] {
  background-color: var(--PetSmart-Blue);
}
.page-petsmart .accordion-header.active,
.page-dogday .accordion-header.active {
  background-color: var(--White);
}
.page-petsmart .tiltedheart,
.tiltedheart {
  background-position: 60% top;
  background-repeat: no-repeat;
}
.page-petsmart .offer-1 {
  border: 0pxpx solid var(--Orange);
  @media (min-width:1024px) {
    padding: 30px;
  }
}
.page-petsmart .checkright h5,
.page-dogday .checkright h5 {
  color: var(--PetSmart-Red) !important;
}
.page-petsmart .checkright p a,
.page-dogday .checkright p a {
  color: var(--PetSmart-Blue) !important;
}
.page-petsmart .checkright input[type="text"], 
.page-petsmart .checkright input[type="password"], 
.page-petsmart .checkright input[type="email"],
.page-dogday .checkright input[type="text"], 
.page-dogday .checkright input[type="password"], 
.page-dogday .checkright input[type="email"] {
  background-color: rgba(211, 211, 211, 0.20);
  border-radius: 12px !important;
}
.page-petsmart .checkright #register input[type="text"]:focus,
.page-petsmart .checkright #register input[type="email"]:focus,
.page-petsmart .checkright #register input[type="password"]:focus,
.page-dogday .checkright #register input[type="text"]:focus,
.page-dogday .checkright #register input[type="email"]:focus,
.page-dogday .checkright #register input[type="password"]:focus  {
  outline: 2px solid var(--PetSmart-Blue);  
}

.shadow-img {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.coupon-display {
  display: flex;
  padding: 30px 48px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border: 2px dashed var(--Heckin-Red);
  background: rgba(230,81,57, 0.10);
  margin-bottom: 20px;
  .textbefore {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.09px;
  }
  .coupontext {
    color: var(--Heckin-Red);
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.09px;
  }
}
